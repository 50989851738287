



































































































@import "../../../css/variables";

.doted {
    position: absolute;
    cursor: pointer;
    bottom: 20px;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    font-size: 14px;

    &:hover .activity-project-content {
        display: flex;
    }
}

.activity-project-content {
    display: none;
    position: absolute;
    top: 17px;
    flex-direction: column;
    width: 300px;
    z-index: 1000;

    padding: 10px 15px;
    border-radius: 4px;
    box-shadow: 0px 0px 4px rgba(33, 33, 33, 0.7);
}

.activity-project-category {
    margin-right: 5px;
}

.activity-project-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.activity-project-name--disabled {
    font-style: italic;
}
