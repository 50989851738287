




























































































































































































































































.select-fixed {
    width: 8rem;

    select {
        width: 8rem;
    }
}
