























































































































































.page-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

img {
    border: 1px solid #f5f5f5;
}

.actions {
    align-self: flex-end;
}

.page-full {
    display: flex;
    flex-flow: column;
}

.columns {
    margin: 0;
    flex-grow: 1;
}
