


























































































































@import "../../../css/variables.scss";

$internal-card-padding: 1rem;

.card--report {
    width: $card-width;
    height: $card-height;
    border-radius: 10px;
    margin: 1rem;
    cursor: pointer;
    overflow: visible;

    flex-shrink: 0;
    flex-grow: 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .card-content {
        cursor: pointer;

        .title {
            margin-bottom: $internal-card-padding;
        }
    }

    .card-header-title {
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: $internal-card-padding;

        .tags {
            margin-bottom: 0;
        }
    }
}

.card-footer--report {
    padding: $card-footer-padding;
    padding-top: $internal-card-padding;
    justify-content: flex-end;
}

.report-date {
    flex-grow: 1;
}

.title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.content {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.team-icon {
    display: inline-flex;
}
.team-count {
    margin-left: 5px;
}

.report-item-dropdown {
    height: 20px;
}

.report-item-dropdown-container {
    padding: 1.5rem;
    padding-bottom: $internal-card-padding;
}

.requestItem-profileIcon {
    .profileIcon-icon {
        border: solid 1px rgba(0, 0, 0, 0.4);
    }
}
