


























































































































































































































































































































































































































































































































































































































.report-period--create {
    .period-date {
        justify-content: start;

        > div {
            flex-direction: row-reverse;
            justify-content: start;
            width: auto;
        }
    }
}

.duration-display-modes-container {
    display: flex;
    align-items: center;
    height: 35px;
}
.hours-in-day-input {
    display: flex;
    align-items: center;
}
.hours-in-day-input-inline {
    padding-left: 5px;
    width: 90px;
}
