





































































































































@import "../../../css/variables";

$gauge-task-pading-left: 8px;

.gauge {
    display: flex;
    justify-content: space-between;
    height: $validationSheet-gauge-height;
}

.gauge-container {
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    width: calc(100% - 200px);
}

.gauge-task {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    font-size: 14px;
    color: white;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.gauge-taskTitle {
    font-weight: 700;
}

.gauge-taskDuration,
.gauge-taskTitle {
    position: relative;
    left: $gauge-task-pading-left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.gauge-hoursCount {
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: min-content;
    flex-direction: column;
    justify-content: center;
    color: $primary;
    width: 200px;

    &.exceed {
        color: #b71540;

        .gauge-hoursDone {
            color: #b71540;
        }
    }
}

.gauge-hoursCountContainer {
    display: flex;
    align-items: center;
    overflow: hidden;
}

.gauge-hoursDone {
    font-weight: 700;
    color: #808e9b;
}

.inlineInput-message {
    position: absolute;
    top: -($validationSheet-gauge-height/2);
    width: 100%;
    padding-right: 2.5rem;
}
