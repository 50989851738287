
















































.field {
    display: flex;
    align-items: center;
}

.label {
    flex-grow: 1;
}

.links {
    > a {
        margin-left: 1rem;
    }
}
