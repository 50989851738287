





































































































































.EditableInput {
    margin: 0;
    position: relative;

    &.title {
        min-width: 395px;

        .text {
            color: #227093;
            font-weight: 300;
            font-size: 28px;
            line-height: 38px;

            padding-bottom: calc(0.3em - 1px);
            padding-left: calc(0.3em - 1px);
            padding-right: calc(0.3em - 1px);
            padding-top: calc(0.3em - 1px);

            height: 2.25rem;
        }

        input {
            font-family: "Open sans";
            color: #227093;
            font-weight: 300;
            font-size: 28px;

            padding-bottom: calc(0.3em - 1px);
            padding-left: calc(0.3em - 1px);
            padding-right: calc(0.3em - 1px);
            padding-top: calc(0.3em - 1px);
        }
    }

    &.row {
        .text {
            padding-bottom: calc(0.3em - 1px);
            padding-left: calc(0.3em - 1px);
            padding-right: calc(0.3em - 1px);
            padding-top: calc(0.3em - 1px);

            height: 23px;
        }

        input {
            width: 90%;
            height: 23px;
            font-family: "Open sans";
            color: #363636;
            font-size: 1em;

            padding-bottom: calc(0.3em - 1px);
            padding-left: calc(0.3em - 1px);
            padding-right: calc(0.3em - 1px);
            padding-top: calc(0.3em - 1px);
        }

        .actions {
            left: calc(90% - 64px);
        }
    }

    &.avatar {
        display: flex;
        justify-content: center;

        min-width: 200px;
        text-align: center;

        .text {
            height: 32px;
            padding-bottom: calc(0.3em - 1px);
            padding-left: calc(0.3em - 1px);
            padding-right: calc(0.3em - 1px);
            padding-top: calc(0.3em - 1px);
        }

        input {
            height: 32px;
            font-family: "Open sans";
            color: #363636;
            font-size: 1em;
            text-align: center;

            padding-bottom: calc(0.3em - 1px);
            padding-left: calc(0.3em - 1px);
            padding-right: calc(0.3em - 1px);
            padding-top: calc(0.3em - 1px);
        }
    }

    &:hover:not(.disabled) span.text {
        background-color: rgb(235, 236, 240);
        cursor: text;
    }

    .actions {
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 2;
    }
}
