

























































































































































.rows-container {
    height: 100%;
}

.selector {
    display: flex;
    align-items: center;
}

.search {
    width: 200px;
}

.rows {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
}

.rows-empty {
    position: relative;
    height: 80%;
}
