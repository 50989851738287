










































































































































































































































































.period {
    display: flex;
}

.period-date {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;

    > div {
        display: flex;
        justify-content: center;
        width: 100%;
        align-items: center;
    }
}
.period-now {
    margin-top: -4px;
}

.period-select {
    flex-shrink: 0;
    margin: 0;
}
