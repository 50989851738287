













































































































.timer-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.timer {
    display: flex;
    justify-content: center;
    width: 100%;
}

.button-timer {
    width: 110px;
}

.timer-value {
    color: #1e272e;
}
