















































































































































































































































































































































































































































































































































































































































































@import "../../css/variables";

.ValidationSheetPage {
    .field-container {
        position: relative;
    }
}

.ValidationSheetPage-container {
    margin: 0 1.5rem;
}

.ValidationSheetPage-label {
    font-weight: 700;
    font-size: 18px;
    margin-right: 0.5rem;
}

.ValidationSheetPage-desc {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .ValidationSheetPage-label {
        font-size: 14px;
    }
}

.ValidationSheetPage-field {
    display: flex;
    align-items: center;
}

.ValidationSheetPage-table {
    .b-table {
        height: auto;
    }
}

.ValidationSheetPage-buttons {
    margin: 0 1.5rem;
}

.have-issue {
    text-decoration: line-through;
}
