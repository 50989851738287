


















































@import "../../../css/variables";

.inlineInput-field {
    .input {
        border: none;
        background-color: #f7f7f7;
        color: $primary;
        box-shadow: none;
        padding: 0 40px 0 0;

        &.is-danger {
            color: $danger;
        }
    }

    .control {
        .icon {
            display: none;
            height: 100%;
        }
        &.has-icons-right {
            .icon {
                display: inline-flex;
            }
        }
    }
}

.inlineInput-message {
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 12px;
    color: $danger;
    font-size: 14px;
}
