














































































.is-normal {
    width: 155px;
}

.is-small {
    width: 120px;
}
