











































































































































































































































@import "../../../css/variables";

.doted {
    position: absolute;
    cursor: pointer;
    bottom: 20px;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    font-size: 14px;

    &:hover .event--content {
        display: flex;
    }
}

.event--content {
    display: none;
    position: absolute;
    top: 17px;
    flex-direction: column;
    z-index: 1000;

    padding: 10px 15px;
    border-radius: 4px;
    box-shadow: 0px 0px 4px rgba(33, 33, 33, 0.7);

    i {
        margin-right: 5px;
    }

    a {
        color: inherit;
    }
}
