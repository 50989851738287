





















































































.picker {
    position: relative;
}

.colors {
    display: flex;
    flex-wrap: wrap;
    border-radius: 3px;
    padding-top: 4px;
    position: absolute;
    top: 100%;
    z-index: 20;

    background-color: white;
    width: 190px;
}

.picker:not(.disabled) {
    .color {
        &:hover {
            cursor: pointer;
            border: 4px solid rgba(0, 0, 0, 0.25);
        }
    }
}
