






























.page {
    justify-content: center;
    display: flex;
}

.header {
    display: flex;
    height: 40px;
    width: 100%;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #f0f0f0;
}

.logo {
    width: 24px;
    height: 24px;
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

.page-container {
    margin-top: 40px;
    padding: 85px 15px;
    max-width: 960px;
}
