
































































































































































































.charts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    > div {
        box-sizing: border-box;
        padding: 1rem;
        margin: 1.5rem;
        border: 1px solid #d2dae2;
        border-radius: 10px;
    }

    > div.counter,
    > div.bar,
    > div.pie {
        width: calc(50% - 3rem);
    }

    > div.table {
        width: calc(100% - 3rem);
        min-height: 300px;
    }
}

.users {
    cursor: pointer;
}

.user {
    display: flex;
    align-items: center;
}
