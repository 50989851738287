













































































































































































































































































.charts-selection {
    .b-table .table-wrapper {
        overflow: visible;
    }

    thead {
        display: none;
    }
}
