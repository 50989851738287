










































































































































































































































@font-face {
    font-family: "bangers";
    src: url("../../fonts/Bangers.ttf");
    font-weight: normal;
    font-style: normal;
}

.site {
    padding-top: 81px;
}

.site_header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 81px;
    background: #ebf6fb;

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.logo {
    height: 49px;
}

.actions {
    position: absolute;
    top: 0;
    right: 0px;
    height: 100%;
    display: flex;
    align-items: center;

    .lang {
        margin-left: 15px;

        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45px;
        height: 100%;
        background-color: #dfeef5;
        color: #216695;

        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
    }

    .lang-caret {
        margin-left: 3px;
        font-size: 10px;
    }

    .lang-items {
        position: absolute;
        top: 81px;
        left: 0;

        background: #ffffff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .lang-item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45px;
        height: 76px;
    }

    .action {
        width: 135px;
    }

    .action-secondary {
        background-color: white;
        margin-left: 5px;

        &:hover {
            background-color: #227093;
        }
    }
}

.main {
    display: flex;
    justify-content: center;
    min-height: 405px;
    flex-wrap: wrap-reverse;
    padding: 20px;

    > img {
        width: 268px;
        margin: 20px 41px;
    }
}

.section-title {
    color: black;
    font-family: "bangers";
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 38px;
    margin-bottom: 24px;
}

.section-content {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
}

.slogan {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin: 20px 41px;

    img {
        position: absolute;
        width: 153.96px;
        height: 154.87px;
        left: -55px;
        top: -70px;
        transform: rotate(20.39deg);
    }
}

.slogan-title {
    position: relative;
    color: black;
    font-family: "bangers";
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 51px;
    width: 488px;
}

.slogan-translate {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #969696;
}

.slogan-text {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    margin-top: 31px;
    width: 410px;
    color: black;
}

.features {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    min-height: 269px;
}

.feature {
    margin: 20px 87px;

    div {
        width: 185px;
        margin-top: 25px;
        font-family: Bangers;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 26px;
        text-align: center;
        color: #000000;
    }
}

.times {
    min-height: 604px;
    background: #e5e5e5;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}

.times_screenshot {
    width: 309px;
    height: 249px;
    margin: 0 83px;
    position: relative;

    :nth-child(1) {
        position: absolute;
        transform: rotate(-4deg);
        max-width: inherit;
        width: 340px;
        // top: 30px;
        // left: 33px;
        // clip-path: inset(0 36% 0 0);
        top: 32px;
        left: 3px;
        clip-path: inset(0 27% 0 8%);
    }
    :nth-child(2) {
        position: relative;
    }
}

.times-content {
    width: 380px;
    position: relative;
    margin: 170px 83px 20px 83px;
}

.times-content .logo {
    position: absolute;
    width: 267px;
    height: 216px;
    left: -143px;
    top: -170px;
}

.times-content .section-content {
    margin-bottom: 28px;
}

.times-icons {
    display: flex;

    > img {
        width: 50px;
        height: 50px;
        margin-right: 13px;
    }
}

.report {
    height: 604px;
    background: white;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
    align-items: center;

    > img {
        width: 500px;
        margin: 0 62px;
    }
}

.report-content {
    width: 319px;
    margin: 0 62px;
}

.demo {
    min-height: 600px;
    background: #e5e5e5;
    display: flex;
    justify-content: center;
    align-items: center;

    video {
        margin-top: 20px;
    }
}

.demo-content {
    text-align: center;
    margin: 0 83px;
}

.demo-action {
    text-align: center;
    margin-top: 20px;
}

.donnate {
    min-height: 427px;
    background: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    > img {
        width: 358px;
        margin: 0 83px;
    }
}

.donnate-action {
    text-align: center;
    margin-top: 20px;
}

.contact {
    min-height: 494px;
    background: #e5e5e5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact_section {
    position: relative;
    width: 672px;
}

.contact_email {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
}

.contact_gitlab {
    position: absolute;
    bottom: 0;
    right: 0;
    text-align: center;
}

.button.is-primary.is-outlined.contact_button {
    color: #485460;
    border-color: #485460;

    img {
        margin-right: 12px;
    }

    &:hover {
        background-color: #48546023;
    }

    &:focus {
        background-color: transparent;
    }
}

.site_footer {
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
        margin: 0 10px;
    }
}
