

































































.hour-now {
    position: absolute;
    overflow: visible;
    user-select: none;
    top: 20px;
    width: 70px;

    &::before {
        content: "";
        position: absolute;
        height: 6px;
        width: 6px;
        left: -2px;
        top: 21px;
        background: #ff7262;
        border-radius: 5px;
    }

    &::after {
        content: "";
        position: absolute;
        height: 155px;
        width: 1px;
        border: 1px solid #ff7262;
        left: 0;
        top: 26px;
    }

    > div {
        width: 100%;
        margin-left: -50%;
        color: #ff7262;
        font-weight: bold;
    }

    &.is-green {
        &::before {
            background: #2ed573;
        }

        &::after {
            border: 1px solid #2ed573;
        }

        > div {
            color: #2ed573;
        }
    }
}
