



































@import "../../../css/variables";

$default-page-padding: 2.5rem;

.page {
    min-height: 100%;
    display: flex;

    font-family: "Open Sans", BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial",
        sans-serif;
}

.page-content {
    position: relative;
    display: flex;
    flex-direction: column;

    // Leave space for menu (only on big screens)
    width: calc(100% - 100px);
    left: 100px;
    @media (max-width: 800px) {
        width: 100%;
        left: 0px;
    }
}

.page-full {
    background-color: #f7f7f7;
    flex-grow: 1;
    position: relative;
}

.page-full--padded {
    padding: $default-page-padding / 2 $default-page-padding;
}

.page-title {
    display: flex;
    margin: $default-page-padding;

    color: $primary;
    font-weight: 300;
    font-size: 28px;
    line-height: 38px;

    @media (max-width: 800px) {
        margin: 10px;
    }

    > * {
        margin: 0 1rem;
    }

    .back-button {
        margin: 0 1rem 0 0;
    }
}

.page-section-title {
    display: flex;
    margin: $default-page-padding 0;
    align-items: center;

    color: $primary;
    font-weight: 700;
    font-size: 18px;
    line-height: 38px;

    @media (max-width: 800px) {
        margin: 10px;
    }

    > * {
        margin: 0 1rem;
    }
}

.page-desc {
    position: relative;
    top: 4px;
    font-style: italic;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    align-self: center;

    color: #000000;
}

.page-center {
    display: flex;
    justify-content: center;
    min-height: 100%;
    padding: 1rem;
}

.page-message {
    display: flex;
    flex-direction: column;
    align-self: center;

    text-align: center;
    font-style: italic;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;

    width: 200px;
    > div,
    > img {
        margin: 15px;
    }

    > .svg-inline--fa.fa-w-12 {
        width: 200px;
    }
}

.page-form {
    max-width: 960px;
    box-shadow: 0 0 0 1px #e5e5e5;
    border-bottom-right-radius: 0.25rem;
    border-radius: 0.25rem;

    flex-grow: 1;
    padding: 20px;
    height: min-content;
}

.page-footer {
    position: fixed;
    bottom: 10px;

    a {
        margin: 0 10px;
    }
}

.b-tabs {
    padding: 1rem;
}

.field-inline {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .fixed {
        width: 15rem;
    }

    > div,
    button {
        margin-left: 0.5rem;
    }
}

.field-container {
    padding: $page-field-container-padding;
}

.input {
    height: 2.25rem;
}

.b-table {
    min-height: 100%;
}

.b-table .table-wrapper {
    // height: 100%;
    min-height: 200px;
    overflow: visible;
}

.table {
    background-color: #f7f7f7;
    padding: 1rem;
    cursor: pointer;
}

.table td {
    vertical-align: middle;

    &.fix-half {
        width: 50%;
    }

    &.fix-third {
        width: 30%;
    }

    &.fix-size {
        width: 100px;
    }

    &.fix-size-color {
        width: 32px;
    }

    &.fix-size-action {
        width: 110px;
    }

    &.fix-size-icon {
        width: 28px;
        padding: 0.5em 0;
    }
}

.b-tabs {
    height: 100%;
}

.page-full {
    section {
        height: calc(100% - 41px);

        .tab-item {
            height: 100%;

            > div {
                position: relative;
                height: 100%;

                .b-table {
                    position: relative;
                    height: calc(100% - 41px);
                }
            }
        }
    }

    .field-container {
        height: calc(100% - 41px);

        .b-table {
            position: relative;
            height: calc(100% - 41px);
        }
    }
}

.avatar {
    display: inline;
    display: flex;
    align-items: center;

    figure {
        margin-right: 0.5rem;
    }
}
