































.color {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    margin: 3px;
    border: 4px solid white;

    &.selected {
        border: 4px solid rgba(0, 0, 0, 0.5);
    }
}
