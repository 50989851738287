
















































































































































































































































































































































































$unselected-task-opacity: 0.6;

.tasks.is-editing .task:not(.is-editing) {
    opacity: $unselected-task-opacity !important;
}

.tasks.hasSelection .task:not(.is-selected),
.tasks.is-updating .task:not(.is-updating) {
    opacity: $unselected-task-opacity !important;
}
