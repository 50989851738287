


































































































.zoomTimeline {
    flex-wrap: nowrap;
    display: flex;
    align-items: center;

    > img {
        margin-right: 0;
    }
}

.zoomTimeline-lock {
    cursor: default;
    margin-right: 10px;
}

.zoomTimeline-sign {
    width: 12px;
    height: 12px;
    cursor: pointer;

    &.deactivated {
        opacity: 0.6;
    }
}

.zoomTimeline-digits {
    width: 35px;
}
