








































































.update-notification {
    pointer-events: all;
}
