





































.tab-forms {
    box-shadow: 0 0 0 1px #e5e5e5;
    border-bottom-right-radius: 0.25rem;
    border-radius: 0.25rem;
    width: 460px;
}
