








































































































































































































































































































































































.header {
    border-bottom: solid 1px #d2dae2;
    padding-bottom: 1.5rem;
}

.level-item {
    cursor: pointer;
}

.date {
    width: 9rem;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

strong {
    color: #4a4a4a;
}
