














































.hour-under-cursor {
    position: absolute;
    overflow: visible;
    user-select: none;
    top: 20px;
    width: 70px;

    &::before {
        content: "";
        position: absolute;
        height: 6px;
        width: 6px;
        left: -2px;
        top: 21px;
        background: #0a3d62;
        border-radius: 5px;
    }

    &::after {
        content: "";
        position: absolute;
        height: 155px;
        width: 1px;
        border: 1px solid #0a3d62;
        left: 0;
        top: 26px;
    }

    > div {
        width: 100%;
        margin-left: -50%;
        color: #0a3d62;
        font-weight: bold;
    }
}
