





















































































.scroll {
    position: relative;
    width: calc(100% - 1 * 100px);
    height: 20px;
    background: #fff;
    display: flex;
    align-items: center;

    &.disabled {
        .scroller {
            left: 0 !important;
            opacity: 0.5;
            width: calc(100% + 100px);
        }
    }
}

.scroller {
    height: 100%;
    width: 100px;
    position: absolute;
    left: 0;

    &.scrolling {
        opacity: 0.8;
    }

    &::after {
        content: "";
        position: absolute;
        top: 7px;
        height: 6px;
        width: 100%;
        border-radius: 31px;
        background: #808e9b;
    }
}
