












































































































































































































































































































































































































































































































































$animation-duration: 200ms;

.timeline-container {
    position: relative;
    width: 100%;
    // 1 <= scale <= 60
    --scale: 1;
    --scroll: 0;
    --limitScroll: 0;
    --hourMarkHeight: 155;
    --hourMarkTop: 46;
}

.timeline {
    position: relative;
    left: calc(-1 * var(--scale) * var(--scroll) * var(--limitScroll));
    // 1px -> 60s -> 24 x 60 = 1440px = 100%
    // 1px -> 1s  -> 24 x 60 x 60 = 86400px = 6000%
    width: calc(var(--scale) * 100%);
    height: 205px;
    transition: width $animation-duration linear, left $animation-duration linear;
}

.hour {
    position: absolute;
    top: 0;
    overflow: visible;
    user-select: none;

    > div {
        width: 100%;
        margin-left: -50%;
        color: #485460;
    }

    &::after {
        content: "";
        position: absolute;
        height: calc(var(--hourMarkHeight) * 1px);
        width: 1px;
        border: 1px dashed #808e9b;
        left: 0;
        top: calc(var(--hourMarkTop) * 1px);
    }
}

.hour-half,
.hour-quarter,
.hour-five {
    opacity: 0;
    transition: opacity $animation-duration linear;
}

.active-hour-half {
    .hour-half {
        opacity: 1;
    }
}

.active-hour-quarter {
    .hour-quarter {
        opacity: 1;
    }
}

.active-hour-five {
    .hour-five {
        opacity: 1;
    }
}

.select-rectangle {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}
.select-create-task {
    position: absolute;
    background-color: rgba(10, 61, 98, 0.6);
    border-radius: 4px;
    top: 75px;
    height: 85px;
}
