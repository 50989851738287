


















































































































































































































.action-label {
    font-size: 0.875rem;
    line-height: 0.875rem;
    font-weight: bold;
}
