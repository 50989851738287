

















































































@import "../../../css/variables";
.table td {
    &.fix-size-duration {
        width: 200px;
    }
}
