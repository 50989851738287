@charset "utf-8";

html,
body {
    height: 100%;
}
.color,
.tag.color {
    &-0 {
        background-color: #079992;
        color: white;
    }
    &-1 {
        background-color: #0a3d62;
        color: white;
    }
    &-2 {
        background-color: #60a3bc;
        color: white;
    }
    &-3 {
        background-color: #e58e26;
        color: white;
    }
    &-4 {
        background-color: #b71540;
        color: white;
    }
    &-5 {
        background-color: #e55039;
        color: white;
    }
    &-6 {
        background-color: #2ed573;
        color: white;
    }
    &-7 {
        background-color: #222f3e;
        color: white;
    }
    &-8 {
        background-color: #8395a7;
        color: white;
    }
    &-9 {
        background-color: #e84393;
        color: white;
    }
    &-10 {
        background-color: #3b3b98;
        color: white;
    }
    &-11 {
        background-color: #cc8e35;
        color: white;
    }
    &-12 {
        background-color: #58b19f;
        color: white;
    }
    &-13 {
        background-color: #45aaf2;
        color: white;
    }
    &-14 {
        background-color: #c56cf0;
        color: white;
    }
}

// Import a Google Font
@import url("https://fonts.googleapis.com/css2?family=Open+Sans");

// Import global variables
@import "./variables";

// Import fontawesome as font
$fa-font-path: "../../node_modules/fork-awesome/fonts";
@import "../../node_modules/fork-awesome/scss/fork-awesome.scss";

// Import only what you need from Bulma and Buefy
@import "../../node_modules/bulma/sass/utilities/_all.sass";
@import "../../node_modules/bulma/sass/base/_all.sass";
@import "../../node_modules/bulma/sass/helpers/_all.sass";
@import "../../node_modules/bulma/sass/elements/_all.sass";
@import "../../node_modules/bulma/sass/form/_all.sass";
@import "../../node_modules/bulma/sass/components/_all.sass";
@import "../../node_modules/bulma/sass/layout/_all.sass";
@import "../../node_modules/bulma/sass/grid/_all.sass";
@import "../../node_modules/buefy/src/scss/buefy";

* {
    user-select: none;
}

.selectable *, input {
    user-select: text;
}

.is-tag {
    display: inline-block;
    color: #707070;
    font-size: 12px;
    line-height: 20px;
    padding: 0 7px;
    border: 1px solid #e5e5e5;
    border-radius: 100px;
    font-weight: 400;
}

// Don't have the right style on paginated table into message box, see AdvancedTeam.vue as example
a.pagination-link {
    text-decoration: none !important;
}

a.pagination-link.is-current {
    color: #fff !important;
}

a.pagination-link[disabled] {
    color: #7a7a7a !important;
}

// Move the date picker to the foreground
.datepicker .dropdown-menu {
    z-index: 99;
}
