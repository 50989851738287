










































































































































@import "../../../css/variables";

.collaboratorList {
    display: flex;
    flex-wrap: wrap;

    .profileIcon {
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;

        .profileIcon-icon {
            cursor: pointer;
            opacity: 0.8;

            &:hover {
                opacity: 1;
            }
        }

        &.selected {
            .profileIcon-icon {
                opacity: 1;
            }

            &::after {
                font-family: "ForkAwesome";
                content: "\f00c";
                position: absolute;
                top: -5px;
                right: calc(0.5rem - 5px);
                width: 24px;
                height: 24px;
                border-radius: 12px;
                text-align: center;
                border: $field-border 1px solid;

                background-color: white;
                color: $success;
            }
        }

        &.disabled {
            .profileIcon-icon {
                cursor: auto;
                opacity: 0.5;

                &:hover {
                    opacity: 0.5;
                    border: none;
                }
            }
        }
    }
}
