


























































.profileIcon-circle {
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: 500;
    text-transform: uppercase;
}
