







































































































































.EditableTags {
    margin: 0;
    position: relative;
    height: 27px;
    width: 90%;

    .actions {
        position: absolute;
        right: 0;
        z-index: 2;
        top: calc(100% + 4px);
    }

    .tags {
        position: relative;
        height: 100%;
    }
}
