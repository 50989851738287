// Use this files to define global sass variables

// Update Bulma's global variables
$body-background-color: white;
$primary: #227093;
$danger: #c0392b;
$success: #48c774;
$field-border: #dbdbdb;
$link: $primary;
$pagination-margin: 0 1rem 0 0;

// Page variables
$page-field-container-padding: 1rem;

// ReportItem variables
$card-width: 282px;
$card-height: 400px;
$card-shadow: 0 0px 0 1px #d2dae2;
$card-header-shadow: none;
$card-header-padding: 1.5rem;
$card-content-padding: 0 1.5rem;
$card-footer-padding: 1.5rem;
$card-footer-border-top: none;
$card-gap: 1rem;

// ValidationSheet variables
$validationSheet-gauge-height: 60px;
$validationSheet-form-padding: 1.5rem;
