






































































































































$task-border-width: 15px;
$selected-task-opacity: 0.9;

.task {
    position: absolute;
    height: 85px;
    top: 75px;
    padding: 10px 0;
    overflow: visible;

    border-radius: 4px;
    box-shadow: 0px 0px 4px rgba(33, 33, 33, 0.7);

    transition-property: opacity, transform;
    transition-duration: 0.1s;

    will-change: opacity, transform;
}

.task:hover {
    opacity: 0.9;
}

.task.is-editing {
    opacity: 1 !important;
}

.task.is-updating {
    opacity: $selected-task-opacity;
    z-index: 38;
}

.task.is-moving {
    opacity: $selected-task-opacity;
    cursor: move;
}

.task.is-selected {
    opacity: $selected-task-opacity;
}

.task-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin: 0 16px;

    div {
        font-size: 14px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

strong {
    color: inherit;
}

.task-border {
    position: absolute;
    top: 0;
    height: 100%;
    width: $task-border-width;
    cursor: col-resize;
}

.task-border--left {
    left: -$task-border-width / 3;
}

.task-border--right {
    right: -$task-border-width / 3;
}
