














































































































































































































































































































































.options {
    .fix-size {
        select {
            width: 150px;
        }
    }
}
