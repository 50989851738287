







































































.history-item {
    width: 400px;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &.is-active {
        background-color: transparent;
        color: #4a4a4a;
    }
}
